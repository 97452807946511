<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>运营区域电子围栏</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="card__container">
      <div class="site-name">站点名称：{{ areaInfo.siteName || "--" }}</div>
      <a-space>
        <a-button type="primary" @click="onAddPolygon">添加多边形</a-button>
        <a-button @click="onEditPolygon">修改</a-button>
        <a-button @click="onSavePolygon">保存</a-button>
        <a-button type="danger" ghost @click="onDeletePolygon">删除</a-button>
      </a-space>
      <div class="tip-container">
        <div class="tip">提示：双击鼠标结束绘制</div>
        <div class="tip red">删除后，需点击【保存】，记录电子围栏的改动</div>
      </div>
      <div id="map"></div>
    </div>
  </a-layout>
</template>

<script>
import BreadcrumbItem from "@/components/BreadcrumbItem.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import {
  editOperationArea,
  operateAreaDetail,
  queryOperationArea
} from "@/services/OperationService";
import { Modal } from "ant-design-vue";

export default {
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      map: null,
      lng: this.$route.query.lng,
      lat: this.$route.query.lat,
      isAddPolygon: false,
      polygonList: [],
      polyEditorList: [],
      polygonIndex: null,
      areaId: this.$route.params.areaId,
      areaInfo: {},
      parkList: [],
      markerList: [],
      isEdit: false
    };
  },
  mounted() {
    this.loadDetail();
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEdit) {
      Modal.confirm({
        title: "提示",
        content: "当前页面未保存，请确认是否离开",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          next();
        }
      });
    } else {
      next();
    }
  },
  methods: {
    // 加载详情
    loadDetail() {
      queryOperationArea(this.areaId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.areaInfo = resp.data.data || {};
          this.loadParkingStation();
        }
      });
    },
    // 绘制运营区域的多边形
    drawAreaPolygon() {
      this.polygonList = [];
      let pathList = JSON.parse(this.areaInfo.fencePoints);
      if (pathList && pathList.length > 0) {
        pathList.forEach(path => {
          let polygon = new window.AMap.Polygon({
            path: path,
            strokeColor: "#569df3",
            fillColor: "#1791fc"
          });
          this.map.add(polygon);
          this.polygonList.push(polygon);
          polygon.isClick = false;
          let that = this;
          polygon.on("click", function() {
            that.polygonIndex = null;
            if (that.polygonList.length > 0) {
              that.polygonList.forEach(ele => {
                ele.isClick = false;
                ele.setOptions({
                  fillColor: "#1791fc",
                  zIndex: 10
                });
              });
            }
            polygon.isClick = !polygon.isClick;
            that.polygonIndex = that.polygonList.indexOf(polygon);
            polygon.setOptions({
              fillColor: !polygon.isClick ? "#1791fc" : "#7bccc4",
              zIndex: 10
            });
          });
        });
      }
      if (this.polygonList.length > 0) {
        this.polyEditorList = [];
        window.AMap.plugin("AMap.PolyEditor", () => {
          this.polygonList.forEach(polygon => {
            let polyEditor = new window.AMap.PolyEditor(this.map, polygon);
            this.polyEditorList.push(polyEditor);
          });
        });
      }
    },
    // 初始化地图
    initMap() {
      AMapLoader.load({
        key: "8ff794d16e2643c4d0ef597224524cb3",
        version: "2.0",
        plugins: [
          "AMap.AutoComplete",
          "AMap.PlaceSearch",
          "AMap.Scale",
          "AMap.OverView",
          "AMap.ToolBar",
          "AMap.MapType",
          "AMap.PolyEditor",
          "AMap.Geocoder",
          "AMap.AutoComplete",
          "AMap.CircleEditor",
          "AMap.Driving",
          "AMap.service",
          "AMap.DistrictSearch",
          "AMap.Geolocation",
          "AMap.Marker",
          "AMap.LngLat",
          "AMap.Polygon",
          "AMap.Circle",
          "AMap.GeometryUtil"
        ]
      }).then(AMap => {
        this.map = new AMap.Map("map", {
          center: [this.lng, this.lat],
          zoom: 17,
          viewMode: "2D"
        });
        this.drawParkPoint();
        this.drawAreaPolygon();
        const toolBar = new AMap.ToolBar({
          position: {
            bottom: "90px",
            right: "40px"
          }
        });
        this.map.addControl(toolBar);
      });
    },
    // 绘制停车点
    drawParkPoint() {
      this.markerList = [];
      if (this.parkList.length > 0) {
        this.parkList.forEach(park => {
          let imagePath = require("@/assets/park-station.png");
          // 创建一个自定义图标
          let icon = new window.AMap.Icon({
            // 使用相对路径作为图标的 URL
            image: imagePath,
            // 图标展示的大小
            size: new window.AMap.Size(28, 46),
            // 图标取图偏移量
            imageOffset: new window.AMap.Pixel(0, 0)
          });
          const marker = new window.AMap.Marker({
            position: new window.AMap.LngLat(park.centerLng, park.centerLat),
            offset: new window.AMap.Pixel(-14, -46), //相对于基点的偏移位置
            icon: icon
          });
          marker.setzIndex(60);
          this.map.add(marker);
          this.markerList.push([park.centerLng, park.centerLat]);
          let circle = new window.AMap.Circle({
            center: [park.centerLng, park.centerLat],
            radius: park.outline,
            fillColor: "#1791fc",
            zIndex: 20
          });
          this.map.add(circle);
          // 创建信息窗体内容
          let content = `<div>${park.parkName}</div>`;
          let infoWindow = new window.AMap.InfoWindow({
            content: content,
            offset: new window.AMap.Pixel(0, -50)
          });
          let that = this;
          marker.on("click", function() {
            infoWindow.open(that.map, marker.getPosition());
          });
        });
      }
    },
    // 添加多边形
    onAddPolygon() {
      this.isEdit = true;
      this.isAddPolygon = true;
      this.$message.info("开始绘制");
      if (this.polyEditorList.length > 0) {
        this.polyEditorList.forEach(polyEditor => {
          polyEditor.close();
        });
      }
      this.drawPolygon();
    },
    // 绘制多边形
    drawPolygon() {
      if (this.isAddPolygon) {
        window.AMap.plugin("AMap.MouseTool", () => {
          let mouseTool = new window.AMap.MouseTool(this.map);
          mouseTool.polygon();
          mouseTool.on("draw", e => {
            this.isAddPolygon = false;
            const polygon = e.obj;
            if (polygon.getPath().length <= 2) {
              this.$message.info("请绘制多边形");
              this.map.remove(polygon);
              return;
            }
            polygon.isClick = false;
            this.polygonList.push(polygon);
            let that = this;
            polygon.on("click", function() {
              that.polygonIndex = null;
              if (that.polygonList.length > 0) {
                that.polygonList.forEach(ele => {
                  ele.isClick = false;
                  ele.setOptions({
                    fillColor: "#1791fc",
                    zIndex: 10
                  });
                });
              }
              polygon.isClick = !polygon.isClick;
              that.polygonIndex = that.polygonList.indexOf(polygon);
              polygon.setOptions({
                fillColor: !polygon.isClick ? "#1791fc" : "#7bccc4",
                zIndex: 10
              });
            });
            if (that.polygonList.length > 0) {
              that.polyEditorList = [];
              window.AMap.plugin("AMap.PolyEditor", () => {
                that.polygonList.forEach(polygon => {
                  let polyEditor = new window.AMap.PolyEditor(
                    that.map,
                    polygon
                  );
                  that.polyEditorList.push(polyEditor);
                });
              });
            }
            mouseTool.close();
          });
        });
      }
    },
    // 编辑多边形
    onEditPolygon() {
      this.isEdit = true;
      if (this.polyEditorList.length > 0) {
        this.polyEditorList.forEach(polyEditor => {
          polyEditor.open();
        });
      }
    },
    // 保存多边形
    async onSavePolygon() {
      await this.drawLatestParkStation();
      this.onSubmit();
    },
    // 提交
    onSubmit() {
      if (this.checkPolygonOverlap()) {
        return;
      }
      if (this.polyEditorList.length > 0) {
        this.polyEditorList.forEach(polyEditor => {
          polyEditor.close();
        });
      }
      let list = [];
      if (this.polygonList.length > 0) {
        this.polygonList.forEach(ele => {
          let pointList = [];
          if (ele.getPath().length > 0) {
            ele.getPath().forEach(e => {
              pointList.push([e.lng, e.lat]);
            });
          }
          if (pointList.length > 0) {
            list.push(pointList);
          }
        });
      }
      let partIndexNotInAreaList = [];
      let partInAreaList = [];
      let partNotInAreaList = [];
      for (let i = 0; i < this.markerList.length; i++) {
        let isInsideAnyPolygon = false;
        for (let j = 0; j < list.length; j++) {
          let isInside = this.checkCircleInPolygon(this.parkList[i], list[j]);

          if (isInside) {
            isInsideAnyPolygon = true;
            break;
          }
        }
        if (!isInsideAnyPolygon) {
          partIndexNotInAreaList.push(i);
          partNotInAreaList.push(this.parkList[i].parkId);
        } else {
          partInAreaList.push(this.parkList[i].parkId);
        }
      }
      const params = {
        fencePoints: JSON.stringify(list),
        siteId: this.areaInfo.siteId,
        areaId: this.areaId,
        areaName: this.areaInfo.areaName,
        enablePark: partInAreaList,
        unEnablePark: partNotInAreaList
      };
      editOperationArea(params).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.checkPartPointIsInArea(partIndexNotInAreaList);
        }
      });
    },
    // 删除多边形
    onDeletePolygon() {
      this.isEdit = true;
      if (this.polygonIndex !== null && this.polygonIndex >= 0) {
        const that = this;
        this.$confirm({
          title: "确定要删除吗?",
          okText: "确定",
          okType: "danger",
          cancelText: "取消",
          onOk() {
            let polygon = that.polygonList[that.polygonIndex];
            let polyEditor = that.polyEditorList[that.polygonIndex];
            polyEditor.close();
            that.polygonList.splice(that.polygonIndex, 1);
            that.polyEditorList.splice(that.polygonIndex, 1);
            that.polygonIndex = null;
            that.map.remove(polygon);
          }
        });
      } else {
        this.$message.info("请选择要删除的围栏");
      }
    },
    // 校验多边形是否重叠
    checkPolygonOverlap() {
      this.polygonList = this.polygonList.filter(
        polygon => polygon.getPath().length > 2
      );
      let flag = false;
      const length = this.polygonList.length;
      for (let i = 0; i < length - 1; i++) {
        for (let j = i + 1; j < length; j++) {
          if (
            this.isPolygonsOverlap(this.polygonList[i], this.polygonList[j])
          ) {
            this.$message.info("电子围栏存在重叠区域,请重新编辑后再保存");
            flag = true;
            break;
          }
        }
      }
      return flag;
    },
    isPolygonsOverlap(polygon1, polygon2) {
      const path1 = polygon1.getPath();
      const path2 = polygon2.getPath();
      // 判断两个多边形是否相交
      for (let i = 0; i < path1.length; i++) {
        if (window.AMap.GeometryUtil.isPointInRing(path1[i], path2)) {
          return true;
        }
      }
      for (let i = 0; i < path2.length; i++) {
        if (window.AMap.GeometryUtil.isPointInRing(path2[i], path1)) {
          return true;
        }
      }
      return false;
    },
    // 查询停车点
    loadParkingStation() {
      operateAreaDetail(this.areaId).then(resp => {
        if (resp.data.code === "SUCCESS") {
          this.parkList = resp.data.data.areaParkList || [];
          this.initMap();
        }
      });
    },
    // 描绘最新停车点
    drawLatestParkStation() {
      return new Promise((resolve, reject) => {
        // 获取所有的圆形并删除
        const circles = this.map.getAllOverlays("circle");
        circles.forEach(circle => {
          this.map.remove(circle);
        });
        // 获取所有的标记并删除
        const markers = this.map.getAllOverlays("marker");
        markers.forEach(marker => {
          this.map.remove(marker);
        });
        operateAreaDetail(this.areaId)
          .then(resp => {
            if (resp.data.code === "SUCCESS") {
              this.parkList = resp.data.data.areaParkList || [];
              this.drawParkPoint();
              resolve(); // 异步操作完成时 resolve
            } else {
              reject(new Error("操作失败")); // 异步操作失败时 reject
            }
          })
          .catch(error => {
            reject(error); // 异步操作失败时 reject
          });
      });
    },
    // 检查停车点是否在运营区域
    checkPartPointIsInArea(partIndexNotInAreaList) {
      if (partIndexNotInAreaList.length > 0 && this.polygonList.length > 0) {
        let str = "";
        partIndexNotInAreaList.forEach(ele => {
          if (str) {
            str = str + ", " + this.parkList[ele].parkName;
          } else {
            str = this.parkList[ele].parkName;
          }
        });
        this.isEdit = false;
        this.$warning({
          title: "保存成功",
          content: `${str}的电子围栏需要在运营区域里面！请于手机app内修改，否则失效！`,
          okText: "确定"
        });
      } else {
        this.isEdit = false;
        this.$message.success("保存成功");
      }
    },
    // 判断圆是否在多边形内
    checkCircleInPolygon(park, path) {
      let isInside = window.AMap.GeometryUtil.isPointInRing(
        [park.centerLng, park.centerLat],
        path
      );

      if (isInside) {
        for (let i = 0; i < path.length; i++) {
          let distance = window.AMap.GeometryUtil.distanceToSegment(
            [park.centerLng, park.centerLat],
            path[i],
            path[(i + 1) % path.length]
          );
          if (distance < park.outline) {
            isInside = false;
            break;
          }
        }
      }
      return isInside;
    }
  }
};
</script>

<style scoped>
.site-name {
  margin-bottom: 20px;
}

/deep/ .ant-space-item {
  margin-right: 15px !important;
}

#map {
  width: 100%;
  height: 600px;
}

/deep/ .amap-icon img {
  width: 28px;
  height: 46px;
}

.red {
  color: red;
}

.tip-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}
</style>
